import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import styled from "styled-components";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";
import Nav from "./components/Nav";
import React, { useEffect, useState } from "react";

const StyledApp = styled.div`
  line-height: 1.7;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
`;
const Page = styled.main``;
const Container = styled.main`
  max-width: 1170px;
`;

function App() {
  const { pathname, search } = useLocation();
  const isLoginPage = (pathname && pathname.startsWith("/login")) || false;
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (pathname === '/') {
            navigate('ballot/2025-2026', {replace: true});
        }
        (async () => {
            try {
                const result = await fetch(`/api/getUserData`, {
                    method: 'GET',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if ( result.status === 401 ) {
                    if ( !isLoginPage ) {
                        navigate('/login?redirect=' + encodeURIComponent(pathname));
                    }
                } else {
                    if ( isLoginPage ) {
                        let query = new URLSearchParams(search)
                        let redirect = query.get('redirect');
                        let url = redirect && redirect.startsWith('/') ? redirect : '/ballot/2025-2026';
                        navigate(url, {replace: true});
                        navigate(0);
                    } else {
                        setUserData(await result.json());
                    }
                }
            } catch (e) {
                console.log(e);
                if ( !isLoginPage ) {
                    navigate('/login?redirect=' + encodeURIComponent(pathname));
                }
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

  const linkPrefix = "https://www.toxicology.org";

  return (
    <StyledApp className="App">
      <Header userData={userData} linkPrefix={linkPrefix} />
      <Nav linkPrefix={linkPrefix} />

      <Page>
        <section>
          <Container className="container">
            <Outlet />
          </Container>
        </section>
      </Page>

      <Footer linkPrefix={linkPrefix} />
      <ScrollToTop />
    </StyledApp>
  );
}

export default App;
