import styled from "styled-components";
import React, { useState, useCallback, useRef, useEffect } from "react";
import Office from "./Office";
import { blue, PrinterBreak, red } from "../../styles";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const SectionContent = styled.div``;
const Panel = styled.div`
  text-align: left;
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red};
`;
const ButtonPanel = styled.div`
  text-align: center;
  margin: 0 10px;
  @media (min-width: 992px) {
    text-align: left;
  }
  @media print {
    display: none;
  }
`;
const IntroText = styled.p`
  text-align: left;
`;
const Button = styled.button`
  margin: 0 15px 10px 0;
`;
const CompleteStep = styled.div`
  margin: 30px 0 40px;
`;
const InfoLabel = styled.span`
  font-weight: bold;
  color: ${blue};
`;
const Warning = styled.span`
  color: gray;
`;
const Error = styled.span``;

let States = {
  Vote: 1,
  Review: 2,
  Complete: 3,
};
function CantVotePanel({ alreadyVoted, ballotExpired, isLoading }) {
  if (isLoading) {
    return (
      <Panel>
        <Loading>
          <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
        </Loading>
      </Panel>
    );
  } else if (alreadyVoted) {
    return (
      <Panel>
        <p style={{ paddingBottom: 100 }}>
          Your vote has already been recorded. Each member may only vote once.
          Thank you.
        </p>
      </Panel>
    );
  } else if (ballotExpired) {
    return (
      <Panel>
        <p style={{ paddingBottom: 100 }}>
          This ballot is no longer open for voting.
        </p>
      </Panel>
    );
  } else {
    return (
      <Panel>
        The information that you have supplied indicates that you do not have
        voting rights or an update to your membership is required. Please
        contact SOT Headquarters (sothq@toxicology.org) for assistance.
        <br />
        <br />
        (Note: If you have just updated your membership and you are still
        getting this message, you may need to wait a minute and refresh the
        page)
        <br />
        <br />
        <br />
        <br />
      </Panel>
    );
  }
}

const validate = (candidatesByOfficeId, ballot) => {
  let officeIds = Object.keys(candidatesByOfficeId);
  let errors = [];
  officeIds.forEach((officeId) => {
    let office = ballot.offices.find((o) => o.id === officeId);
    if (!office) return;
    let selectedCandidates = candidatesByOfficeId[officeId];
    if (selectedCandidates.length > office.voteFor) {
      errors.push(
        `For the office of ${office.name}, please select only ${office.voteFor} candidate(s). Currently ${selectedCandidates.length} candidates are selected for ${office.name}`
      );
    }
    for (let i = 0; i < selectedCandidates.length; i++) {
      if (
        selectedCandidates[i].type === "writeIn" &&
        (selectedCandidates[i].writeInName || "").trim().length === 0
      ) {
        errors.push(
          `For the office of ${office.name}, there is a Write-In position checked but with no name entered. Please either enter a name or uncheck the Write-In box.`
        );
      }
    }
  });
  return errors;
};

function Ballot({ preview }) {
  const { cycleName } = useParams();
  const [cycle, setCycle] = useState(null);
  const [alreadyVoted, setAlreadyVoted] = useState(false);
  const [candidatesByOfficeId, setCandidatesByOfficeId] = useState({}); // {[officeId: string]: {type: string, writeInIndex: number, writeInName: string, id: string}}
  let totalSelected = (Object.values(candidatesByOfficeId) || []).reduce(
    (prev, cur) => prev + (cur || []).length,
    0
  );
  const [ballot, setBallot] = useState(null);
  const [ballotExpired, setBallotExpired] = useState(false);
  const [canVote, setCanVote] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(States.Vote);
  const [errors, setErrors] = useState([]);
  const topRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setIsLoading(true);
    const fetchCycles = async () => {
      const result = await fetch("/api/getCycles", {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (result.status === 401) {
        navigate("/login?redirect=" + encodeURIComponent(pathname));
      } else {
        const { cycles } = await result.json();

        if (!cycles || cycles.length == 0) return;

        let cycleFound;
        if (cycleName) {
          cycleFound = cycles.find((x) => x.name === cycleName);
        }

        // If we can't find the cycle by it's name (or a name was not provided), we will try to default them to the latest possible cycle.
        if (!cycleFound) {
          cycleFound = cycles[cycles.length - 1];
        }

        setCycle(cycleFound);
      }
      setIsLoading(false);
    };
    fetchCycles().catch(console.error);
  }, []);

  useEffect(() => {
    if (!cycle) return;

    const fetchBallot = async () => {
      setIsLoading(true);

      const result = await fetch(`/api/ballot?cycleId=${cycle._id}`, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (result.status === 401) {
        navigate("/login?redirect=" + encodeURIComponent(pathname));
      } else if (result.status === 403) {
        setCanVote(false);
      } else if (result.status === 200) {
        setIsLoading(false);
        const { ballot, voted } = await result.json();

        if (!ballot) return;
        setAlreadyVoted(voted);
        setBallot(ballot);

        if (
          !preview &&
          new Date().getTime() >= new Date(ballot.endDate).getTime()
        ) {
          setCanVote(false);
          setBallotExpired(true);
        } else {
          setCanVote(!voted);
        }

      } else {
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
    };
    fetchBallot().catch(console.error);
  }, [cycle]);

  useEffect(() => {
    if (!cycle) return;
    const refreshVotingMemberCodes = async () => {
      await fetch(`/api/refreshVotingMemberCodes?cycleId=${cycle._id}`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      });
    };

    refreshVotingMemberCodes().catch(console.error);
  }, [cycle]);

  const toggleCandidateForOfficeId = useCallback(
    (candidate, officeId, toggle) => {
      if (!cycle) return;

      setCandidatesByOfficeId((prevCandidatesByOfficeId) => {
        let update = {};
        let currentCandidates = (
          prevCandidatesByOfficeId[officeId] || []
        ).slice();
        let foundIndex = currentCandidates.findIndex((currentCandidate) => {
          if (candidate.type === "writeIn") {
            if (currentCandidate.type === "writeIn") {
              return candidate.writeInIndex === currentCandidate.writeInIndex;
            } else {
              return false;
            }
          } else {
            if (currentCandidate.type !== "writeIn") {
              return candidate.id === currentCandidate.id;
            } else {
              return false;
            }
          }
        });
        if (toggle) {
          if (foundIndex >= 0) {
            if (currentCandidates[foundIndex].type === "writeIn") {
              if (
                currentCandidates[foundIndex].writeInName !==
                candidate.writeInName
              ) {
                currentCandidates[foundIndex] = candidate;
              } else {
                return prevCandidatesByOfficeId;
              }
            } else {
              return prevCandidatesByOfficeId;
            }
          } else {
            if (
              ballot.offices.find((office) => office.id === officeId)
                .voteFor === 1
            ) {
              currentCandidates = [candidate];
            } else {
              currentCandidates.push(candidate);
            }
          }
        } else {
          if (foundIndex < 0) {
            return prevCandidatesByOfficeId;
          } else {
            currentCandidates.splice(foundIndex, 1);
          }
        }
        update[officeId] = currentCandidates;
        return Object.assign({}, prevCandidatesByOfficeId, update);
      });
    },
    [ballot]
  );

  const submit = async (e) => {
    e.preventDefault();
    if (preview) {
      return;
    }
    if (currentStep === 1) {
      let currentErrors = validate(candidatesByOfficeId, ballot);
      setErrors(currentErrors);
      if (currentErrors.length === 0) {
        setCurrentStep(States.Review);
        setTimeout(() => topRef.current.scrollIntoView(), 0);
      }
    } else if (currentStep === 2) {
      try {
        setIsLoading(true);
        const result = await fetch(`/api/ballot?cycleId=${cycle._id}`, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(candidatesByOfficeId),
        });
        if (result.status === 401) {
          navigate("/login?redirect=" + encodeURIComponent(pathname));
        } else if (result.status === 403) {
          setCanVote(false);
          setCurrentStep(States.Vote);
        } else if (result.status === 200) {
          setCurrentStep(States.Complete);
          setTimeout(() => topRef.current.scrollIntoView(), 0);
        } else {
          setErrors(["An Unknown Error occurred trying to submit your ballot"]);
        }
      } catch (e) {
        setErrors(["An Unknown Error occurred trying to submit your ballot"]);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const back = () => {
    setCurrentStep(States.Vote);
    setTimeout(() => topRef.current.scrollIntoView(), 0);
  };

  if (!isLoading && !cycle)
    return (
      <SectionContent>
        <span className="row">
          <span className="alert alert-info" role="alert">
            Unable to find active Cycle
          </span>
          <br />
        </span>
      </SectionContent>
    );

  return (
    <SectionContent>
      <div ref={topRef} />

      {!canVote ? (
        <CantVotePanel
          alreadyVoted={alreadyVoted}
          isLoading={isLoading}
          ballotExpired={ballotExpired}
        />
      ) : (
        <form id="Form1" method="post">
          {currentStep === States.Vote ? (
            <Panel>
              <IntroText>
                Please review the candidate biographical information and make
                your selection online in the appropriate space next to the
                candidate&lsquo;s name. Candidates are presented in a random
                order per position. (Note that text fields are provided for
                alternative candidates.) Only ballots received by{" "}
                {ballot.endDateFmt}, are counted.
              </IntroText>

              <IntroText>
                If you prefer to vote using a paper ballot by fax; the fax cover
                sheet and ballot are&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="/documents/ballotcard_and_faxcover.pdf"
                >
                  available for download here
                </a>
                .
              </IntroText>

              <br />

              <PrinterBreak />

              <div className="row">
                {ballot.offices.map((office) => (
                  <Office
                    key={office.id}
                    office={office}
                    toggleCandidateForOfficeId={toggleCandidateForOfficeId}
                    selectedCandidates={candidatesByOfficeId[office.id] || []}
                  />
                ))}
              </div>
            </Panel>
          ) : null}

          {!preview && currentStep === States.Review ? (
            <div>
              <Panel id="pnl_validation">
                <label id="lbl_validation" />
              </Panel>
              <Panel id="pnl_page2">
                <p className="warning">
                  Note: You must press Submit below for your vote to be
                  recorded.
                </p>

                <p>Please confirm your choices:</p>

                {ballot.offices.map((office) => {
                  let noneSelected =
                    !candidatesByOfficeId[office.id] ||
                    candidatesByOfficeId[office.id].length === 0;

                  return (
                    <p key={office.id}>
                      <InfoLabel>{office.name}:</InfoLabel>
                      {noneSelected ? (
                        <React.Fragment>
                          <br />
                          <Warning>&nbsp;&nbsp;--None Selected--</Warning>
                        </React.Fragment>
                      ) : null}
                      {(candidatesByOfficeId[office.id] || []).map(
                        (candidate, candIndex) => {
                          return (
                            <React.Fragment key={candIndex}>
                              <br />
                              <span>
                                &nbsp;&nbsp;
                                {candidate.type === "normal"
                                  ? office.candidates.find(
                                      (c) => c.id === candidate.id
                                    ).name
                                  : `Write-In: ${candidate.writeInName}`}
                              </span>
                            </React.Fragment>
                          );
                        }
                      )}
                    </p>
                  );
                })}

                <p>&nbsp;</p>

                <p>
                  If any of the above information is incorrect, please press the
                  Back button below to make changes before submitting.
                </p>

                <p>
                  If all above information is correct, please press
                  &quot;Submit&quot; to record your votes. Once you press
                  submit, your vote cannot be changed.&nbsp;&nbsp;
                </p>

                {totalSelected === 0 ? (
                  <div className="alert alert-danger" role="alert">
                    You have not selected any candidates for any offices. To
                    select candidates for offices, press the Back Button and
                    make your selections.
                  </div>
                ) : null}
              </Panel>
            </div>
          ) : null}

          {!preview && currentStep === States.Complete ? (
            <CompleteStep>
              <Panel>Your vote has been recorded. Thank you.</Panel>
            </CompleteStep>
          ) : null}

          {(!preview && currentStep === States.Vote) ||
          currentStep === States.Review ? (
            <ButtonPanel>
              <p>
                {errors.length ? (
                  <span className="row">
                    <span className="alert alert-danger" role="alert">
                      {errors.map((error, i) => (
                        <React.Fragment>
                          {i > 0 ? <br /> : null}
                          <Error>{error}</Error>
                        </React.Fragment>
                      ))}
                    </span>
                    <br />
                  </span>
                ) : null}
                <span className="row">
                  {currentStep === States.Review ? (
                    <Button
                      className="btn btn-secondary col-xs-12 col-md-4 col-lg-3"
                      onClick={back}
                      disabled={isLoading}
                    >
                      Back
                    </Button>
                  ) : null}
                  <Button
                    className="btn btn-primary col-xs-12 col-md-4 col-lg-3"
                    onClick={submit}
                    disabled={
                      isLoading ||
                      (currentStep === States.Review && totalSelected === 0)
                    }
                  >
                    {isLoading ? (
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    ) : currentStep === States.Vote ? (
                      "Review Your Vote"
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </span>
                <br />
                <br />
                <br />
              </p>
            </ButtonPanel>
          ) : null}
        </form>
      )}
    </SectionContent>
  );
}

export default Ballot;
